import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import get from 'lodash/get';
import PlacesAutocomplete from 'react-places-autocomplete';
import Gallery from '../../Images/Gallery';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { GoogleMapLibrary } from '../../../components';

class SupervisorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supervisor: {
        email: '',
        displayName: '',
        address: '',
        phone: {},
        supervisor: {}
      },
      file: null,
      showGallery: false
    };
  }

  componentWillMount() {
    let { supervisor } = this.props;

    if (supervisor && !supervisor.phone) supervisor.phone = {};
    if (supervisor && !supervisor.supervisor) supervisor.supervisor = {};

    this.setState(ps => ({
      ...ps,
      supervisor: supervisor
        ? supervisor
        : {
            email: '',
            displayName: '',
            address: '',
            phone: {},
            supervisor: {}
          },
      file: null,
      emailValid: '',
      displayNameValid: '',
      jobTitleValid: '',
      addressValid: '',
      mobileValid: '',
      workValid: '',
      landlineValid: ''
    }));
  }

  close() {
    this.props.closeModal();
  }

  handleImageChange(file) {
    let { supervisor } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      supervisor.photoURL = reader.result;
      this.setState(ps => ({ ...ps, supervisor, file: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  onChangeSupervisor(name, nameValid, value, valid) {
    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState(ps => ({
        ...ps,
        supervisor: {
          ...ps.supervisor,
          phone: { ...ps.supervisor.phone, [name]: value }
        },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      }));
    } else {
      if (name === 'job_title') {
        this.setState(ps => ({
          ...ps,
          supervisor: {
            ...ps.supervisor,
            supervisor: { ...ps.supervisor.supervisor, [name]: value }
          },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        }));
      } else {
        this.setState(ps => ({
          ...ps,
          supervisor: { ...ps.supervisor, [name]: value },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        }));
      }
    }
  }

  validateSupervisor() {
    let displayName = this.displayName;
    let email = this.email;
    let jobTitle = this.jobTitle;
    let address = this.address;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;

    this.setState({
      [displayName.attributes.getNamedItem('namevalid').value]: displayName
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      [jobTitle.attributes.getNamedItem('namevalid').value]: jobTitle.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      displayName.validity.valid &&
      email.validity.valid &&
      jobTitle.validity.valid &&
      address.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid
    );
  }

  saveSupervisor() {
    if (this.validateSupervisor()) {
      let { supervisor, file } = this.state;
      this.props.saveSupervisor({ ...supervisor }, { ...file });
    }
  }

  render() {
    let {
        supervisor,
        emailValid,
        displayNameValid,
        jobTitleValid,
        addressValid,
        mobileValid,
        workValid,
        landlineValid,
        showGallery
      } = this.state,
      { t } = this.props;

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {supervisor._id ? t('Edit Supervisor') : t('New Supervisor')}
        </ModalHeader>
        <ModalBody>
          {supervisor ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} className={'display-flex justify-content-center'}>
                  <div
                    className="avatar-image"
                    style={{
                      backgroundImage: `url('${get(
                        supervisor,
                        'photoURL',
                        defaultAvatar
                      )}')`
                    }}
                  />
                </Col>
                <Col xs={12} className={'justify-content-center text-center'}>
                  <Button
                    color={'info'}
                    round="true"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Change</Trans>
                  </Button>
                  <Button
                    color="info"
                    onClick={() => this.setState({ showGallery: true })}
                  >
                    <Trans>Gallery</Trans>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + displayNameValid
                    }
                  >
                    <Label>
                      <Trans>Display Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.displayName = node)}
                      namevalid="displayNameValid"
                      value={supervisor.displayName || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeSupervisor(
                          'displayName',
                          'displayNameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + emailValid}
                  >
                    <Label>
                      <Trans>Email</Trans>
                    </Label>
                    <Input
                      type="email"
                      innerRef={node => (this.email = node)}
                      namevalid="emailValid"
                      value={supervisor.email || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeSupervisor(
                          'email',
                          'emailValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + jobTitleValid}
                  >
                    <Label>
                      <Trans>Job Title</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.jobTitle = node)}
                      namevalid="jobTitleValid"
                      value={supervisor.supervisor.job_title || ''}
                      onChange={event =>
                        this.onChangeSupervisor(
                          'job_title',
                          'jobTitleValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + addressValid}
                  >
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={supervisor.address ? supervisor.address : ''}
                        onChange={address =>
                          this.onChangeSupervisor(
                            'address',
                            'addressValid',
                            address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + mobileValid}
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={supervisor.phone.mobile || ''}
                      onChange={event =>
                        this.onChangeSupervisor(
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + workValid}
                  >
                    <Label>
                      <Trans>Work</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={supervisor.phone.work || ''}
                      onChange={event =>
                        this.onChangeSupervisor(
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + landlineValid}
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={supervisor.phone.landline || ''}
                      onChange={event =>
                        this.onChangeSupervisor(
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validateSupervisor()) {
                this.props.saveSupervisor(supervisor, this.state.file);
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>

        {showGallery ? (
          <Gallery
            type={'profile'}
            selectImage={url =>
              this.setState(prevState => {
                return {
                  ...prevState,
                  showGallery: false,
                  supervisor: {
                    ...prevState.supervisor,
                    photoURL: url
                  }
                };
              })
            }
            closeGallery={() =>
              this.setState(prevState => {
                return { ...prevState, showGallery: false };
              })
            }
          />
        ) : null}
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(SupervisorModal)
);
