import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Map, InfoWindow, Marker } from 'google-maps-react';
import { clientName, proName, supervisorName } from '../../helpers/formatters';

const baseIcon = 'http://maps.google.com/mapfiles/kml/paddle/';

export class MapContainer extends Component {
  state = {
    markers: [],
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {}
  };

  load() {
    let { markers } = this.props;
    let markersState = [];
    if (markers.length) {
      markers.forEach(m => {
        let icon = baseIcon + 'A.png';
        let name = '';

        if (m.roles.indexOf('supervisor') !== -1) {
          icon = baseIcon + 'grn-circle.png';
          name = supervisorName(m);
        }

        if (m.roles.indexOf('pro') !== -1) {
          icon = baseIcon + 'red-circle.png';
          name = proName(m);
        }

        if (m.roles.indexOf('client') !== -1) {
          icon = baseIcon + 'ylw-circle.png';
          name = clientName(m);
        }

        markersState = markersState.concat(
          <Marker
            onClick={this.onMarkerClick}
            key={m._id}
            id={m._id}
            title={name}
            address={m.address ? m.address : ''}
            position={{ lat: m.lat, lng: m.lng }}
            icon={{ url: icon }}
          />
        );
      });
    }
    this.setState({ markers: markersState });
  }

  onMarkerClick = (props, marker) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  componentWillMount() {
    this.load();
  }

  componentWillReceiveProps() {
    this.load();
  }

  render() {
    const style = {
      width: '100%',
      height: '100%'
    };
    let { markers } = this.state;
    return (
      <div className={'map-container-location'}>
        <Map
          google={window.google}
          onClick={this.onMapClicked}
          style={style}
          initialCenter={{
            lat: 48.8910105,
            lng: 2.2346106
          }}
          className={'map'}
          zoom={11}
        >
          {markers}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h6>{this.state.selectedPlace.title}</h6>
              <p>{this.state.selectedPlace.address}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MapContainer));
