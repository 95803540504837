import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../../components/index';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { all, photo, remove, save } from '../../../helpers/actions/sellers';
import { all as allProviders } from '../../../helpers/actions/providers';
import SellerCard from './SellerCard';
import SellerModal from './SellerModal';

class Sellers extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    provider: null,
    providers: [],
    seller: null,
    sellerDelete: null
  };

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search, provider } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search, provider }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.setState({ loading: true });

    let { page, limit, search, provider } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    Promise.all([
      dispatch(all({ limit, offset, search, provider })),
      dispatch(allProviders({ limit: 100 }))
    ])
      .then(([{ data, meta }, providers]) => {
        this.setState({
          data,
          ...meta,
          providers: providers.data,
          loading: false,
          provider
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  async onSaveSeller(sellerEdit, avatar) {
    this.setState({ loading: true, seller: null });

    let { dispatch } = this.props;

    if (sellerEdit.address && sellerEdit.address !== '') {
      await geocodeByAddress(sellerEdit.address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          sellerEdit.lat = lat;
          sellerEdit.lng = lng;
        })
        .catch(() => {
          sellerEdit.lat = null;
          sellerEdit.lng = null;
        });
    } else {
      sellerEdit.lat = null;
      sellerEdit.lng = null;
    }

    dispatch(save(sellerEdit))
      .then(sellerRes => {
        if (sellerRes._id && avatar) {
          dispatch(photo(sellerRes._id, avatar))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deleteSeller(id) {
    this.setState({ loading: true, sellerDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      seller,
      sellerDelete
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            { search: event.target.value, page: 1 },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ seller: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((contact, key) => (
                  <Col key={key} xs={12} sm={12} md={6} lg={4}>
                    <SellerCard
                      seller={contact}
                      onEdit={sellerEdit =>
                        this.setState({ seller: sellerEdit })
                      }
                      onDelete={deleteSeller =>
                        this.setState({ sellerDelete: deleteSeller })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No sellers found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!seller ? (
            <SellerModal
              show={!!seller}
              seller={seller}
              closeModal={() => this.setState({ seller: null })}
              saveSeller={(sellerEdit, avatar) =>
                this.onSaveSeller(sellerEdit, avatar)
              }
            />
          ) : null}

          {!sellerDelete ? null : (
            <AlertConfirm
              message={'The seller cannot be recovered'}
              onCancel={() => this.setState({ sellerDelete: null })}
              onConfirm={() => this.deleteSeller(sellerDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(Sellers));
