import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Users.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const location = params => (dispatch, getState, { api }) =>
  api.Users.location(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
