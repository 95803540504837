import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { PanelHeader, Button, Spinner } from '../../components';
import { location } from '../../helpers/actions/projects';
import MapContainer from './MapProjects';
import Script from 'react-load-script';

class ProjectLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      scriptLoaded: false,
      projects: {},
      month: 1
    };
  }

  load(month = 1) {
    this.setState({ loading: true, month });

    let { dispatch } = this.props;

    dispatch(location({ month }))
      .then(({ data }) => this.setState({ loading: false, projects: data }))
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    let { loading, projects, month, scriptLoaded } = this.state;

    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBmLqBfl1iiPFKL2lNFvbeb-tz4jzZB75Q&libraries=places"
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card className={``}>
            <CardHeader>
              <Row>
                <Col xs={8} md={8}>
                  <h6>
                    <Trans>Projects</Trans>
                    {` - ${projects ? projects.length : 0}`}
                  </h6>
                </Col>
                <Col xs={4} md={4} className={'text-right'}>
                  <Button
                    className={'m-0 ml-2'}
                    size="sm"
                    color={month === 1 ? 'info' : 'default'}
                    onClick={() => this.load(1)}
                  >
                    1
                  </Button>
                  <Button
                    className={'m-0 ml-2'}
                    size="sm"
                    color={month === 3 ? 'info' : 'default'}
                    onClick={() => this.load(3)}
                  >
                    3
                  </Button>
                  <Button
                    className={'m-0 ml-2'}
                    size="sm"
                    color={month === 6 ? 'info' : 'default'}
                    onClick={() => this.load(6)}
                  >
                    6
                  </Button>
                  <Button
                    className={'m-0 ml-2'}
                    size="sm"
                    color={'default'}
                    onClick={() => this.load(month)}
                  >
                    <i className="now-ui-icons arrows-1_refresh-69" />
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <hr />
            <CardBody className={'pt-0'}>
              <Row>
                {projects.length && !loading && scriptLoaded ? (
                  <Col style={{ height: 440 }}>
                    <MapContainer markers={projects} />
                  </Col>
                ) : (
                  <Col className={'text-center'} style={{ height: 440 }}>
                    <h6>
                      <Trans>No projects found</Trans>
                    </h6>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(ProjectLocations));
