import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import get from 'lodash/get';
import { providerName, sellerName } from '../../../helpers/formatters';
import defaultAvatar from '../../../assets/img/default-avatar.png';

class SellerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  render() {
    let { selected } = this.state;
    let { seller } = this.props;

    return (
      <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState({ selected: !selected })}
        >
          <Row>
            <Col xs={3} lg={2} className={'pl-1 pr-1'}>
              <div
                className="client-avatar img-raised"
                style={{
                  backgroundImage: `url('${get(
                    seller,
                    'photoURL',
                    defaultAvatar
                  )}')`
                }}
              />
            </Col>
            <Col xs={9} lg={7}>
              <h6>{sellerName(seller)}</h6>
              {seller.email}
            </Col>
            <Col xs={12} lg={3} className={'text-right pl-1 pr-1'}>
              <Button
                id={`edit-${seller._id}`}
                className={'btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(seller)}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`edit-${seller._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${seller._id}`}
                className={'btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(seller)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${seller._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={'text-center pt-0 pb-0'}
          style={{ display: selected ? 'block' : 'none' }}
        >
          <hr />
          <strong>
            <Trans>Provider</Trans>
          </strong>
          <div>
            <span className={'pr-3'}>
              <i className="fa fa-map-marker text-primary icon-action" />
            </span>
            {providerName(get(seller, 'seller.provider', null))}
          </div>
          <hr />
          <strong>
            <Trans>Address</Trans>
          </strong>
          <div>
            <span className={'pr-3'}>
              <i className="fa fa-map-marker text-primary icon-action" />
            </span>
            {get(seller, 'address', '-')}
          </div>
          <hr />
          <strong>
            <Trans>Phones</Trans>
          </strong>
          <div>
            <ul className="fa-ul inline-block text-left">
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(seller, 'phone.mobile', '-')}  (Mobile)`}
              </li>
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(seller, 'phone.landline', '-')}  (Landline)`}
              </li>
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(seller, 'phone.work', '-')}  (Work)`}
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(SellerCard)
);
