import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { PanelHeader, Spinner, GoogleMapLibrary } from '../../components';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Datetime from 'react-datetime';
import moment from 'moment';
import queryString from 'query-string';
import get from 'lodash/get';
import { one, save, countDevisByProject } from '../../helpers/actions/projects';
import { all as allProviders } from '../../helpers/actions/providers';
import { all as allSupervisors } from '../../helpers/actions/supervisors';
import { all as allClients } from '../../helpers/actions/clients';
import { clientName, supervisorName } from '../../helpers/formatters';
import {
  projectCategories,
  projectType,
  projectTypeAll
} from '../../helpers/nomenclators';
import NavbarProject from './NavbarActions/NavbarProject';
import ClientModal from '../Users/Clients/ClientModal';

export default connect()(
  translate('translations-fr')(
    class extends Component {
      constructor(props) {
        super(props);
        this.state = {
          loading: true,
          project: {
            provider: null,
            client: null,
            type: 'one-piece',
            startDate: moment(),
            endDate: moment()
          },
          countDevis: null,
          providers: [],
          supervisors: [],
          supervisor: null,
          clients: [],
          newClient: null,
          newMap: { title: '', code: '', system: '' }
        };
      }

      componentWillMount() {
        this.setState({ loading: true });

        let { dispatch, match } = this.props;
        let values = queryString.parse(this.props.location.search);

        let promises = [
          dispatch(allProviders({ limit: 100 })),
          dispatch(allSupervisors({ limit: 100 })),
          dispatch(allClients({}))
        ];

        if (match.params.id) {
          promises.push(
            dispatch(
              one(match.params.id, {
                include: ['client', 'provider', 'devis', 'devisBase'],
                fields: [
                  'name',
                  'status',
                  'provider',
                  'type',
                  'surface',
                  'address',
                  'description',
                  'startDate',
                  'endDate',
                  'client',
                  'devis',
                  'devisBase',
                  'children',
                  'parent'
                ]
              })
            )
          );

          promises.push(dispatch(countDevisByProject(match.params.id)));
        } else if (values.parent) {
          promises.push(dispatch(one(values.parent)));
        }

        Promise.all(promises)
          .then(([providers, supervisors, clients, project, countDevis]) => {
            let entity = {};
            if (!project) {
              //create new project
              entity = {
                provider: null,
                client: null,
                maps: [],
                type: 'one-piece',
                startDate: moment(),
                endDate: moment()
              };
            } else if (match.params.id) {
              //edit project
              entity = project;
            } else if (values.parent) {
              //create new child project
              entity = {
                client: project.client,
                provider: project.provider,
                address: project.address,
                maps: [],
                type: 'compound-son',
                startDate: moment(),
                endDate: moment(),
                parent: project._id
              };
            }

            this.setState({
              providers: providers.data,
              supervisors: supervisors.data,
              clients: clients.data,
              project: entity,
              countDevis,
              loading: false
            });
          })
          .catch(() => this.setState({ loading: false }));
      }

      onChange(name, nameValid, value, valid) {
        let { project, supervisor } = this.state;
        project = { ...project, [name]: value };

        if (name === 'client' || name === 'category') {
          project.name = `${get(project.client, 'client.lastname', '')} - ${get(
            project,
            'category',
            ''
          )} - (${supervisorName(supervisor)})`;
        }

        this.setState({
          project,
          [nameValid]: valid ? 'has-success' : 'has-danger'
        });
      }

      onChangeSupervisor(value) {
        let { project } = this.state;
        project.name = `${get(project.client, 'client.lastname', '')} - ${get(
          project,
          'category',
          ''
        )} - (${supervisorName(value)})`;

        this.setState({
          project,
          supervisor: value
        });
      }

      validateProject() {
        let { project } = this.state;

        let name = this.name;
        let address = this.address;
        let surface = this.surface;
        let description = this.description;
        let startDate = this.startDate;
        let endDate = this.endDate;

        this.setState({
          [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
            ? 'has-success'
            : 'has-danger',
          providerValid: project.provider ? 'has-success' : 'has-danger',
          typeValid: project.type ? 'has-success' : 'has-danger',
          [address.attributes.getNamedItem('namevalid').value]: address.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [surface.attributes.getNamedItem('namevalid').value]: surface.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [description.attributes.getNamedItem('namevalid').value]: description
            .validity.valid
            ? 'has-success'
            : 'has-danger',
          [startDate.attributes.getNamedItem('namevalid').value]: startDate
            .validity.valid
            ? 'has-success'
            : 'has-danger',
          [endDate.attributes.getNamedItem('namevalid').value]: endDate.validity
            .valid
            ? 'has-success'
            : 'has-danger'
        });

        return (
          name.validity.valid &&
          surface.validity.valid &&
          project.provider !== null &&
          project.type !== null &&
          address.validity.valid &&
          description.validity.valid &&
          startDate.validity.valid &&
          endDate.validity.valid
        );
      }

      async save() {
        if (this.validateProject()) {
          let { project } = this.state,
            { dispatch, history } = this.props;

          this.setState({ loading: true });

          if (project.address && project.address !== '') {
            await geocodeByAddress(project.address)
              .then(results => getLatLng(results[0]))
              .then(({ lat, lng }) => {
                project.lat = lat;
                project.lng = lng;
              })
              .catch(() => {
                project.lat = null;
                project.lng = null;
              });
          } else {
            project.lat = null;
            project.lng = null;
          }

          let data = {
            name: project.name || '',
            client: project.client || null,
            provider: project.provider || null,
            type: project.type || null,
            surface: project.surface || null,
            address: project.address || '',
            lat: project.lat,
            lng: project.lng,
            description: project.description || '',
            startDate: project.startDate || null,
            endDate: project.endDate || null,
            parent: project.parent || null
          };

          if (project._id) data._id = project._id;

          dispatch(save(data))
            .then(response => {
              if (project.type !== 'compound-son')
                history.push(`/projects/${response._id}`);
              else history.push(`/projects/${project.parent}`);
            })
            .catch(() => this.setState({ loading: false }));
        }
      }

      selectNewClient(client) {
        this.setState({ loading: true });

        let { project, supervisor } = this.state,
          { dispatch } = this.props;
        project.client = client;
        project.name = `${clientName(client)} - ${get(
          project,
          'category',
          ''
        )} - (${supervisorName(supervisor)})`;

        if (!project.address) {
          project.address = client.address;
        }

        dispatch(allClients({}))
          .then(clients =>
            this.setState({ project, clients: clients.data, loading: false })
          )
          .catch(() => this.setState({ loading: false }));
      }

      createNewClient() {
        let { project, providers } = this.state;
        let photoUrl = null;
        if (project.provider) {
          let provider = providers.filter(p => p._id === project.provider._id);
          if (provider.length && provider[0].logo) photoUrl = provider[0].logo;
        }
        this.setState(ps => ({ ...ps, newClient: { photoUrl } }));
      }

      render() {
        let {
            loading,
            project,
            providers,
            supervisors,
            supervisor,
            clients,
            newClient,
            countDevis
          } = this.state,
          { t } = this.props;

        return (
          <div>
            <PanelHeader size="sm" />
            <div className="content">
              <NavbarProject project={project} history={this.props.history} />
              <Card style={{ position: 'relative' }}>
                {loading ? <Spinner inside={true} /> : null}
                <CardHeader>
                  <h6>{project._id ? t('Edit Project') : t('New Project')}</h6>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className={'has-label ' + this.state.nameValid}
                        >
                          <Label>
                            <Trans>Name</Trans>
                          </Label>
                          <Input
                            type="text"
                            innerRef={node => (this.name = node)}
                            namevalid="nameValid"
                            value={project.name || ''}
                            required="required"
                            onChange={event =>
                              this.onChange(
                                'name',
                                'nameValid',
                                event.target.value,
                                event.target.validity.valid
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            <Trans>Category</Trans>
                          </Label>
                          <CreatableSelect
                            className="primary"
                            closeOnSelect={true}
                            value={project.category || null}
                            options={projectCategories.map(pc => ({
                              label: pc,
                              value: pc
                            }))}
                            onNewOptionClick={event => {
                              projectCategories.push(event.value);
                              this.onChange(
                                'category',
                                'categoryValid',
                                event ? event.value : null,
                                !!event
                              );
                            }}
                            onChange={event =>
                              this.onChange(
                                'category',
                                'categoryValid',
                                event ? event.value : null,
                                !!event
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className={'has-label'}>
                          <Label>
                            <Trans>Supervisor</Trans>
                          </Label>
                          <Select
                            className="primary"
                            clearable={false}
                            options={supervisors.map(p => ({
                              label: supervisorName(p),
                              value: p._id
                            }))}
                            value={supervisor ? supervisor._id : null}
                            required={true}
                            onChange={event => {
                              this.onChangeSupervisor(
                                event
                                  ? supervisors.find(p => p._id === event.value)
                                  : null
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={'has-label ' + this.state.typeValid}
                        >
                          <Label>
                            <Trans>Type</Trans>
                          </Label>
                          <Select
                            className="primary"
                            clearable={false}
                            disabled={
                              !!project.parent ||
                              (!!project._id &&
                                project.type &&
                                project.type === 'compound' &&
                                project.children &&
                                project.children.length > 0) ||
                              (!!project._id &&
                                project.type &&
                                project.type !== 'compound' &&
                                countDevis &&
                                countDevis.total > 0)
                            }
                            options={
                              project.type !== 'compound-son'
                                ? projectType.map(pt => {
                                    return { label: t(pt), value: pt };
                                  })
                                : projectTypeAll.map(pt => {
                                    return { label: t(pt), value: pt };
                                  })
                            }
                            value={project.type || null}
                            required={true}
                            onChange={event => {
                              this.onChange(
                                'type',
                                'typeValid',
                                event ? event.value : null,
                                !!event
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={'has-label ' + this.state.providerValid}
                        >
                          <Label>
                            <Trans>Provider</Trans>
                          </Label>
                          <Select
                            className="primary"
                            clearable={false}
                            options={providers.map(p => ({
                              label: p.name,
                              value: p._id
                            }))}
                            value={
                              project.provider ? project.provider._id : null
                            }
                            required={true}
                            disabled={!!project._id || !!project.parent}
                            onChange={event => {
                              this.onChange(
                                'provider',
                                'providerValid',
                                event
                                  ? providers.find(p => p._id === event.value)
                                  : null,
                                !!event
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={'has-label ' + this.state.addressValid}
                        >
                          <Label>
                            <Trans>Address</Trans>
                          </Label>
                          <GoogleMapLibrary>
                            <PlacesAutocomplete
                              value={project.address ? project.address : ''}
                              onChange={address =>
                                this.onChange(
                                  'address',
                                  'addressValid',
                                  address,
                                  address !== null && address !== ''
                                )
                              }
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps
                              }) => (
                                <div>
                                  <input
                                    ref={node => (this.address = node)}
                                    required="required"
                                    {...getInputProps({
                                      className: 'form-control',
                                      namevalid: 'addressValid',
                                      disabled: !!project.parent
                                    })}
                                  />
                                  <ul
                                    className={
                                      'dropdown-menu inner ' +
                                      (suggestions.length === 0 ? '' : 'show')
                                    }
                                  >
                                    {suggestions.map(suggestion => (
                                      <li
                                        {...getSuggestionItemProps(suggestion)}
                                      >
                                        <a href="/#" className="dropdown-item">
                                          <span className="text">
                                            {suggestion.description}
                                          </span>
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </GoogleMapLibrary>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={
                            'has-label form-validation-40 ' +
                            this.state.surfaceValid
                          }
                        >
                          <Label>
                            <Trans>Surface</Trans> (m
                            <sup>2</sup>)
                          </Label>
                          <Input
                            type="number"
                            innerRef={node => (this.surface = node)}
                            namevalid="surfaceValid"
                            value={project.surface || 1}
                            step={0.1}
                            min={1}
                            required="required"
                            onChange={event =>
                              this.onChange(
                                'surface',
                                'surfaceValid',
                                event.target.value,
                                event.target.validity.valid
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={'has-label ' + this.state.startDateValid}
                        >
                          <Label>
                            <Trans>Start Date</Trans>
                          </Label>
                          <Datetime
                            locale={'fr'}
                            value={moment(project.startDate)}
                            inputProps={{
                              ref: node => (this.startDate = node),
                              namevalid: 'startDateValid',
                              className: 'form-control datetime-read-only',
                              readOnly: true
                            }}
                            onChange={moment =>
                              this.onChange(
                                'startDate',
                                'startDateValid',
                                moment,
                                !!moment
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={
                            'has-label form-validation-40 ' +
                            this.state.endDateValid
                          }
                        >
                          <Label>
                            <Trans>End Date</Trans>
                          </Label>
                          <Datetime
                            isValidDate={c => {
                              let end = moment(c);
                              let start = moment(project.startDate);
                              return end.isSameOrAfter(
                                moment(start, 'DD-MM-YYYY')
                              );
                            }}
                            locale={'fr'}
                            value={moment(project.endDate)}
                            inputProps={{
                              ref: node => (this.endDate = node),
                              namevalid: 'endDateValid',
                              className: 'form-control datetime-read-only',
                              readOnly: true
                            }}
                            onChange={moment =>
                              this.onChange(
                                'endDate',
                                'endDateValid',
                                moment,
                                !!moment
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <FormGroup
                          className={'has-label ' + this.state.descriptionValid}
                        >
                          <Label>
                            <Trans>Description</Trans>
                          </Label>
                          <Input
                            type="textarea"
                            innerRef={node => (this.description = node)}
                            namevalid="descriptionValid"
                            value={project.description || ''}
                            onChange={event => {
                              this.onChange(
                                'description',
                                'descriptionValid',
                                event.target.value,
                                event.target.validity.valid
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup
                          className={'has-label ' + this.props.clienteValid}
                        >
                          <Label>
                            <Trans>Client</Trans>
                          </Label>
                          <Select
                            className="primary"
                            disabled={!!project.parent}
                            options={clients.map(c => ({
                              label: clientName(c),
                              value: c._id
                            }))}
                            value={project.client ? project.client._id : null}
                            onChange={event => {
                              if (!project.address) {
                                project.address = clients.find(
                                  c => c._id === event.value
                                ).address;
                              }
                              this.setState({ project }, () =>
                                this.onChange(
                                  'client',
                                  'clienteValid',
                                  event
                                    ? clients.find(c => c._id === event.value)
                                    : null,
                                  !!event
                                )
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <Button
                          className={'mb-0 mt-4'}
                          color="info"
                          disabled={!!project.parent}
                          onClick={() => this.createNewClient()}
                        >
                          <Trans>New Client</Trans>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter className="text-right clearfix">
                  <Button
                    color="info"
                    className="float-right"
                    onClick={() => this.save()}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </CardFooter>
              </Card>

              {!!newClient ? (
                <ClientModal
                  show={!!newClient}
                  client={newClient}
                  dispatch={this.props.dispatch}
                  onCancel={() => this.setState({ newClient: null })}
                  onConfirm={client =>
                    this.setState({ newClient: null }, () =>
                      this.selectNewClient(client)
                    )
                  }
                />
              ) : null}
            </div>
          </div>
        );
      }
    }
  )
);
