import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import get from 'lodash/get';
import PlacesAutocomplete from 'react-places-autocomplete';
import { all as allProviders } from '../../../helpers/actions/providers';
import Gallery from '../../Images/Gallery';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { GoogleMapLibrary } from '../../../components';

class SellerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      seller: {
        email: '',
        displayName: '',
        address: '',
        phone: {},
        seller: {}
      },
      file: null,
      showGallery: false,
      providers: []
    };
  }

  componentWillMount() {
    let { seller, dispatch } = this.props;

    if (seller && !seller.phone) seller.phone = {};
    if (seller && !seller.seller) seller.seller = {};

    dispatch(allProviders({ limit: 100 }))
      .then(providers => {
        this.setState(ps => ({
          ...ps,
          seller: seller
            ? seller
            : {
                email: '',
                displayName: '',
                address: '',
                phone: {},
                seller: {}
              },
          file: null,
          emailValid: '',
          displayNameValid: '',
          providerValid: '',
          addressValid: '',
          mobileValid: '',
          workValid: '',
          landlineValid: '',
          providers: providers.data,
          loading: false
        }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  close() {
    this.props.closeModal();
  }

  handleImageChange(file) {
    let { seller } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      seller.photoURL = reader.result;
      this.setState(ps => ({ ...ps, seller, file: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  onChangeSeller(name, nameValid, value, valid) {
    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState(ps => ({
        ...ps,
        seller: {
          ...ps.seller,
          phone: { ...ps.seller.phone, [name]: value }
        },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      }));
    } else {
      if (name === 'provider') {
        this.setState(ps => ({
          ...ps,
          seller: {
            ...ps.seller,
            seller: { ...ps.seller.seller, [name]: value }
          },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        }));
      } else {
        this.setState(ps => ({
          ...ps,
          seller: { ...ps.seller, [name]: value },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        }));
      }
    }
  }

  validateSeller() {
    let { seller } = this.state;
    let displayName = this.displayName;
    let email = this.email;
    let address = this.address;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;

    this.setState({
      [displayName.attributes.getNamedItem('namevalid').value]: displayName
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      providerValid:
        seller && seller.seller && seller.seller.provider
          ? 'has-success'
          : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      displayName.validity.valid &&
      email.validity.valid &&
      seller &&
      seller.seller &&
      seller.seller.provider &&
      address.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid
    );
  }

  saveSeller() {
    if (this.validateSeller()) {
      let { seller, file } = this.state;
      this.props.saveSeller({ ...seller }, { ...file });
    }
  }

  render() {
    let {
        seller,
        emailValid,
        displayNameValid,
        providerValid,
        addressValid,
        mobileValid,
        workValid,
        landlineValid,
        showGallery,
        providers
      } = this.state,
      { t } = this.props;

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {seller._id ? t('Edit Seller') : t('New Seller')}
        </ModalHeader>
        <ModalBody>
          {seller ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} className={'display-flex justify-content-center'}>
                  <div
                    className="avatar-image"
                    style={{
                      backgroundImage: `url('${get(
                        seller,
                        'photoURL',
                        defaultAvatar
                      )}')`
                    }}
                  />
                </Col>
                <Col xs={12} className={'justify-content-center text-center'}>
                  <Button
                    color={'info'}
                    round="true"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Change</Trans>
                  </Button>
                  <Button
                    color="info"
                    onClick={() => this.setState({ showGallery: true })}
                  >
                    <Trans>Gallery</Trans>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + displayNameValid
                    }
                  >
                    <Label>
                      <Trans>Display Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.displayName = node)}
                      namevalid="displayNameValid"
                      value={seller.displayName || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeSeller(
                          'displayName',
                          'displayNameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + emailValid}
                  >
                    <Label>
                      <Trans>Email</Trans>
                    </Label>
                    <Input
                      type="email"
                      innerRef={node => (this.email = node)}
                      namevalid="emailValid"
                      value={seller.email || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeSeller(
                          'email',
                          'emailValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + providerValid}
                  >
                    <Label>
                      <Trans>Provider</Trans>
                    </Label>
                    <Select
                      className="primary"
                      clearable={false}
                      options={providers.map(p => ({
                        label: p.name,
                        value: p._id
                      }))}
                      value={
                        seller.seller && seller.seller.provider
                          ? seller.seller.provider._id
                          : null
                      }
                      required={true}
                      disabled={!!seller._id}
                      onChange={event => {
                        this.onChangeSeller(
                          'provider',
                          'providerValid',
                          event
                            ? providers.find(p => p._id === event.value)
                            : null,
                          !!event
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + addressValid}
                  >
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={seller.address ? seller.address : ''}
                        onChange={address =>
                          this.onChangeSeller(
                            'address',
                            'addressValid',
                            address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + mobileValid}
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={seller.phone.mobile || ''}
                      onChange={event =>
                        this.onChangeSeller(
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + workValid}
                  >
                    <Label>
                      <Trans>Work</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={seller.phone.work || ''}
                      onChange={event =>
                        this.onChangeSeller(
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + landlineValid}
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={seller.phone.landline || ''}
                      onChange={event =>
                        this.onChangeSeller(
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validateSeller()) {
                this.props.saveSeller(seller, this.state.file);
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>

        {showGallery ? (
          <Gallery
            type={'profile'}
            selectImage={url =>
              this.setState(prevState => {
                return {
                  ...prevState,
                  showGallery: false,
                  seller: {
                    ...prevState.seller,
                    photoURL: url
                  }
                };
              })
            }
            closeGallery={() =>
              this.setState(prevState => {
                return { ...prevState, showGallery: false };
              })
            }
          />
        ) : null}
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(SellerModal)
);
