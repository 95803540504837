import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.LeroyMerlin.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.LeroyMerlin.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const byType = type => (dispatch, getState, { api }) =>
  api.LeroyMerlin.byType(type).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const byTypeAndId = params => (dispatch, getState, { api }) =>
  api.LeroyMerlin.byTypeAndId(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const productsByTypeAndIdGet = params => (dispatch, getState, { api }) =>
  api.LeroyMerlin.productsByTypeAndIdGet(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
