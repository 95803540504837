import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Map, InfoWindow, Marker } from 'google-maps-react';

export class MapContainer extends Component {
  state = {
    markers: [],
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {}
  };

  load() {
    let { markers } = this.props;
    let markersState = [];
    if (markers.length) {
      markers.forEach(m => {
        markersState = markersState.concat(
          <Marker
            onClick={this.onMarkerClick}
            key={m._id}
            id={m._id}
            title={m.name ? m.name : ''}
            description={m.description ? m.description : ''}
            status={m.status ? m.status : ''}
            address={m.address ? m.address : ''}
            position={{ lat: m.lat, lng: m.lng }}
            icon={{
              url: 'http://maps.google.com/mapfiles/kml/pal2/icon10.png'
            }}
          />
        );
      });
    }
    this.setState({ markers: markersState });
  }

  onMarkerClick = (props, marker) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  componentWillMount() {
    this.load();
  }

  componentWillReceiveProps() {
    this.load();
  }

  render() {
    const style = {
      width: '100%',
      height: '100%'
    };
    let { markers } = this.state,
      { t } = this.props;
    return (
      <div className={'map-container-location'}>
        <Map
          google={window.google}
          onClick={this.onMapClicked}
          style={style}
          initialCenter={{
            lat: 48.8910105,
            lng: 2.2346106
          }}
          className={'map'}
          zoom={11}
        >
          {markers}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <a href={`../projects/${this.state.selectedPlace.id}`}>
                <h6>
                  {this.state.selectedPlace.title} -{' '}
                  <label style={{ color: 'red' }}>
                    {t(this.state.selectedPlace.status)}
                  </label>
                </h6>
              </a>
              <p>{this.state.selectedPlace.address}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MapContainer));
