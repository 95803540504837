import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../../components/index';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { all, photo, remove, save } from '../../../helpers/actions/supervisors';
import SupervisorCard from './SupervisorCard';
import SupervisorModal from './SupervisorModal';

class Supervisors extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    filters: {},
    search: '',
    supervisor: null,
    supervisorDelete: null
  };

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(search) {
    this.setState({ search, page: 1 }, () => this.load());
  }

  async onSaveSupervisor(supervisorEdit, avatar) {
    this.setState({ loading: true, supervisor: null });

    let { dispatch } = this.props;

    if (supervisorEdit.address && supervisorEdit.address !== '') {
      await geocodeByAddress(supervisorEdit.address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          supervisorEdit.lat = lat;
          supervisorEdit.lng = lng;
        })
        .catch(() => {
          supervisorEdit.lat = null;
          supervisorEdit.lng = null;
        });
    } else {
      supervisorEdit.lat = null;
      supervisorEdit.lng = null;
    }

    //delete supervisorEdit.photoURL;
    dispatch(save(supervisorEdit))
      .then(supervisorRes => {
        if (supervisorRes._id && avatar) {
          dispatch(photo(supervisorRes._id, avatar))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deleteSupervisor(id) {
    this.setState({ loading: true, supervisorDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      supervisor,
      supervisorDelete
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event => this.filter(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ supervisor: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((contact, key) => (
                  <Col key={key} xs={12} sm={12} md={6} lg={4}>
                    <SupervisorCard
                      supervisor={contact}
                      onEdit={supervisorEdit =>
                        this.setState({ supervisor: supervisorEdit })
                      }
                      onDelete={deleteSupervisor =>
                        this.setState({ supervisorDelete: deleteSupervisor })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No supervisors found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!supervisor ? (
            <SupervisorModal
              show={!!supervisor}
              supervisor={supervisor}
              closeModal={() => this.setState({ supervisor: null })}
              saveSupervisor={(supervisorEdit, avatar) =>
                this.onSaveSupervisor(supervisorEdit, avatar)
              }
            />
          ) : null}

          {!supervisorDelete ? null : (
            <AlertConfirm
              message={'The supervisor cannot be recovered'}
              onCancel={() => this.setState({ supervisorDelete: null })}
              onConfirm={() => this.deleteSupervisor(supervisorDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(Supervisors)
);
