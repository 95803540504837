import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Sellers.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Sellers.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = supervisor => (dispatch, getState, { api }) =>
  api.Sellers.save(supervisor)
    .then(response => {
      dispatch(notify('info', 'Seller saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Sellers.del(id)
    .then(response => {
      dispatch(notify('info', 'Seller removed'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const photo = (id, file) => (dispatch, getState, { api }) =>
  api.Sellers.photo(id, file)
    .then(response => {
      dispatch(notify('info', 'Seller photo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
