import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { PanelHeader, Button, Spinner } from '../../components';
import { location } from '../../helpers/actions/users';
import MapContainer from './MapUsers';
import Script from 'react-load-script';

class UsersLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      scriptLoaded: false,
      users: {},
      month: 6,
      role: ''
    };
  }

  load() {
    this.setState({ loading: true });

    let { month, role } = this.state,
      { dispatch } = this.props;

    dispatch(location({ month, role }))
      .then(({ data }) => this.setState({ loading: false, users: data }))
      .catch(() => this.setState({ loading: false }));
  }

  setMonth(month) {
    this.setState({ month }, () => {
      this.load();
    });
  }

  setRole(role) {
    this.setState({ role }, () => {
      this.load();
    });
  }

  componentWillMount() {
    this.load();
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    let { loading, users, month, role } = this.state;

    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBmLqBfl1iiPFKL2lNFvbeb-tz4jzZB75Q&libraries=places"
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12} md={12}>
              <Card className={``}>
                <CardHeader>
                  <Row>
                    <Col xs={2} md={2}>
                      <h6>
                        <Trans>Users</Trans>
                        {` - ${users ? users.length : 0}`}
                      </h6>
                    </Col>
                    <Col xs={9} md={9} className={'text-right'}>
                      <label>
                        <Trans>Role</Trans>:
                      </label>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={role === 'client' ? 'info' : 'default'}
                        onClick={() => this.setRole('client')}
                      >
                        <Trans>Cli</Trans>
                      </Button>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={role === 'pro' ? 'info' : 'default'}
                        onClick={() => this.setRole('pro')}
                      >
                        <Trans>Pro</Trans>
                      </Button>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={role === 'supervisor' ? 'info' : 'default'}
                        onClick={() => this.setRole('supervisor')}
                      >
                        <Trans>Sup</Trans>
                      </Button>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={role === '' ? 'info' : 'default'}
                        onClick={() => this.setRole('')}
                      >
                        <Trans>All</Trans>
                      </Button>
                      <label className={'ml-4'}>
                        <Trans>Month</Trans>:
                      </label>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={month === 1 ? 'info' : 'default'}
                        onClick={() => this.setMonth(1)}
                      >
                        1
                      </Button>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={month === 3 ? 'info' : 'default'}
                        onClick={() => this.setMonth(3)}
                      >
                        3
                      </Button>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={month === 6 ? 'info' : 'default'}
                        onClick={() => this.setMonth(6)}
                      >
                        6
                      </Button>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={month === '' ? 'info' : 'default'}
                        onClick={() => this.setMonth('')}
                      >
                        <Trans>All</Trans>
                      </Button>
                    </Col>
                    <Col xs={1} md={1} className={'text-right'}>
                      <Button
                        className={'m-0 ml-2'}
                        size="sm"
                        color={'default'}
                        onClick={() => this.load()}
                      >
                        <i className="now-ui-icons arrows-1_refresh-69" />
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <hr />
                <CardBody className={'pt-0'}>
                  <Row>
                    {users.length && !loading ? (
                      <Col style={{ height: 440 }}>
                        <MapContainer markers={users} />
                      </Col>
                    ) : (
                      <Col className={'text-center'} style={{ height: 440 }}>
                        <h6>
                          <Trans>No users found</Trans>
                        </h6>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(UsersLocations));
